import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';

import { Spinner } from '../../components';

const apiUrl = process.env.REACT_APP_SUBSCRIPTION_API;

class Subscription extends Component {

    state = {
        message: null,
        error: null
    };

    componentDidMount() {

        const search = this.props.location.search;
        const query = new URLSearchParams(search);
        const confirmation = query.get('confirmation');

        // Send confirmation to back-end
        const request = {
            method: 'post',
            url: `${apiUrl}${this.props.match.path}`,
            crossDomain: true,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                confirmation
            }
        };

        axios(request)
            .then(response => {
                const message = response.data.message;
                this.setState({ message });
            })
            .catch(error => {
                this.setState({ error });
            });
    }

    render() {

        let message;

        if (this.state.message) {
            message = (
                <Fragment>
                    <h2>{this.state.message}</h2>
                    <Link to='/' className="section-main__link">return</Link>
                </Fragment>
            );
        }

        else if (this.state.error) {
            message = (
                <Fragment>
                    <h2>Sorry, something went wrong. Please try submitting your request again.</h2>
                    <Link to='/' className="section-main__link">return</Link>
                </Fragment>
            );
        }

        else {
            message = <Spinner />;
        }


        return (


            <div className="main">
                <section className="section-main">
                    <div className="shell">
                        <div className="section__entry">
                            <h1>WWDO Mailing List</h1>
                            {message}
                        </div>
                    </div>
                </section>
            </div>
        );

    }
}

export default Subscription;

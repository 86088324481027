import React from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const DynamicForm = props => {

    let formValues = props.formValues.map(val => {

        let emailNotice = val.type === 'email' ?
        (
          <Form.Text>
            We will never sell or share your information. Period.
          </Form.Text>
        ) : null

        return (
            <Form.Group key={val.name}>
                <Form.Control
                type={val.type}
                name={val.name}
                placeholder={val.placeholder}
                required={val.required || false}
                className='field'
                onChange={(event) => props.changed(event)}></Form.Control>
                {emailNotice}
            </Form.Group>
        );
    })

    return(
            <div className='form' ref={props.innerRef}>
                <Form bsPrefix='bs-form'>
                    {formValues}
                    <div className='form__actions'>
                        <Button
                          type='submit'
                          onClick={props.submit}
                          variant='-green form__btn'
                        >Submit</Button>
                    </div>
                </Form>
            </div>
    )
}

export default React.forwardRef((props, ref) => <DynamicForm innerRef={ref} {...props} />);

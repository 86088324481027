import React, { Component } from 'react';

import { DynamicForm, Spinner } from '../../components';
import Button from 'react-bootstrap/Button';
import {updateObject} from '../../shared/utility';

import axios from 'axios';

const apiUrl = process.env.REACT_APP_SUBSCRIPTION_API;
const apiKey = process.env.REACT_APP_SUBSCRIPTION_API_KEY;

/*
    Note:

    These are the values expected by the subscription API
    for the form name:

    state.form := ('community' || 'jobseeker' || 'employer')
*/

class LandingPage extends Component {

    state = {
        form: null,
        user: {
            firstName: '',
            lastName: '',
            companyName: '',
            email: ''
        },
        isProcessing: false,
        error: false,
        success: false
    };

    formRef = React.createRef();

    toggleForm = (formName) => {
        if (this.state.form === formName) {
            this.setState({
                form: null
            });
        } else {
            this.setState({
                form: formName,
                error: null,
                success: null
            });
        }
    };

    scrollToForm = () => {
        if (this.formRef.current) {
            this.formRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    changedInput = (event) => {
        event.preventDefault();
        let name = event.target.name;
        let value = event.target.value;
        const updatedUser = updateObject(this.state.user, {
            [name]: value
          })
        this.setState({user: updatedUser})
    };

    onFormSubmit = async(e) => {
        e.preventDefault();

        this.setState({
            isProcessing: true
        });

        const subscription = {
            user : {
                ...this.state.user
            },
            subscriptionType: this.state.form
        };

        const request = {
            method: 'post',
            url: `${apiUrl}/subscribe`,
            crossDomain: true,
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': apiKey
            },
            data: subscription
        };

        try {

            let res = await axios(request);

            if (res) {
                this.setState({
                    isProcessing: false,
                    success: true
                });
            }

        }

        catch (err) {
            this.setState({
                isProcessing: false,
                error: true
            });
        }
    }

    componentDidUpdate () {
        this.scrollToForm();
    }

    render () {

        let message = null;

        let infoForm = null;

        let employerForm = [
            {
                type: 'text',
                name: 'firstName',
                placeholder: 'First Name',
                required: true
            },
            {
                type: 'text',
                name: 'lastName',
                placeholder: 'Last Name',
                required: true
            },
            {
                type: 'text',
                name: 'companyName',
                placeholder: 'Company Name',
                required: true
            },
            {
                type: 'email',
                name: 'email',
                placeholder: 'Work Email Address',
                required: true
            }
        ];

        let jobseekerForm = [
            {
                type: 'text',
                name: 'firstName',
                placeholder: 'First Name'
            },
            {
                type: 'text',
                name: 'lastName',
                placeholder: 'Last Name'
            },
            {
                type: 'email',
                name: 'email',
                placeholder: 'Email Address',
                required: true
            }
        ];

        let communityForm = [
            {
                type: 'text',
                name: 'firstName',
                placeholder: 'First Name',
                required: true
            },
            {
                type: 'text',
                name: 'lastName',
                placeholder: 'Last Name'
            },
            {
                type: 'text',
                name: 'companyName',
                placeholder: 'Company Name (if applicable)'
            },
            {
                type: 'email',
                name: 'email',
                placeholder: 'Email Address',
                required: true
            }
        ];

        if (this.state.isProcessing) {
            infoForm = <Spinner />;
        }

        else {
            switch (this.state.form) {

                case 'employer':
                    infoForm = (
                        <DynamicForm
                            ref={this.formRef}
                            formValues={employerForm}
                            changed={this.changedInput}
                            submit={this.onFormSubmit}
                        />
                    );
                    break;

                case 'jobseeker':
                    infoForm = (
                        <DynamicForm
                            ref={this.formRef}
                            formValues={jobseekerForm}
                            changed={this.changedInput}
                            submit={this.onFormSubmit}
                        />
                    );
                    break;
                case 'community':
                    infoForm = (
                        <DynamicForm
                            ref={this.formRef}
                            formValues={communityForm}
                            changed={this.changedInput}
                            submit={this.onFormSubmit}
                        />
                    );
                    break;

                default:
                    infoForm = null;
            }
        }

        if (this.state.error) {
            infoForm = null;
            message = (<h2>Sorry, something went wrong. Please try submitting your information again.</h2>)
        } else if (this.state.success) {
            infoForm = null;
            message = (<h2>Thank you! You'll be hearing from us soon.</h2>)
        }

        return (
            <div className="main">
                <section className="section-main">
                    <div className="shell">

                        <header className="section__head">

                            <h1>The Community-focused DevOps Jobs Site</h1>

                            <h2>
                                Find Work. Do Good. Build Community.<br /><br />
                            </h2>

                        </header>

                            <div className='box'>
                                <h3>
                                    Be the first to know more.<br />
                                    Sign up for one of our mailing lists below.<br /><br />
                                </h3>
                                <div className='section-main__actions'>
                                    <Button onClick={() => this.toggleForm('community')} variant='-blue'>I Want To Build Community</Button>
                                    <Button onClick={() => this.toggleForm('jobseeker')} variant='-blue'>I'm Seeking Work</Button>
                                    <Button onClick={() => this.toggleForm('employer')} variant='-blue'>I'm Hiring</Button>
                                </div>
                                    {infoForm}
                                    {message}
                            </div>

                    </div>
                </section>
            </div>
        )
    }
}

export default LandingPage;

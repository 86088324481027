import React from 'react';

import './Spinner.css';

const Spinner = props => (

  <div className="spinner">Loading &hellip;</div>

);

export default Spinner;

import React from 'react';

import Logo from './Logo';

const SiteHeader = props => {

	const zIndex = {
		zIndex: '200'
	};

	return (
    <header className="header header--black" style={zIndex}>
			<div className="shell">
				<div className="header__entry">

					<Logo image="/logo-expanded.png" />

				</div>
			</div>
		</header>
  );

};

export default SiteHeader;

import React, { Component } from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './App.css';

import { LandingPage, Subscription } from './containers';
import { Header, SiteFooter } from './components';


class App extends Component {
  render() {
    return (
      <BrowserRouter>

      <div className="App">

        <Header />

        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route path='/subscribe/new' component={Subscription} />
          <Route path='/unsubscribe' component={Subscription} />
        </Switch>

        <SiteFooter />

      </div>

      </BrowserRouter>
    );
  }
}

export default App;

import React from 'react';
import './SiteFooter.css';

import {faFacebookF, faTwitter, faLinkedinIn} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SiteFooter = () => {

    const twitterUrl = 'https://twitter.com/weworkdevops1';
    const facebookUrl = 'https://www.facebook.com/weworkdevops1';
    const linkedinUrl = 'https://www.linkedin.com/company/weworkdevops/';
    const email = 'contact@weworkdevops.com';

    return (
        <div className='footer'>
            <div className='columnContainer'>
                <h1>Powered by <a href='http://particle41.com' target='blank'>Particle41</a></h1>
                <h6>&copy; {(new Date()).getFullYear()} Particle41</h6>
            </div>

            <div className='socialAndContact'>
                <div className='columnContainer listBox'>
                    <label htmlFor='socialMediaList' className='standardCursor'>Connect With Us</label>
                    <table className='socialMediaList' name='socialMediaList'>
                        <tbody>
                            <tr>
                                <td><a href={facebookUrl} target='blank'><FontAwesomeIcon icon={faFacebookF} /></a></td>
                                <td><a href={facebookUrl} target='blank'>Facebook</a></td>
                            </tr>
                            <tr>
                                <td><a href={twitterUrl} target='blank'><FontAwesomeIcon icon={faTwitter} /></a></td>
                                <td><a href={twitterUrl} target='blank'>Twitter</a></td>
                            </tr>
                            <tr>
                                <td><a href={linkedinUrl} target='blank'><FontAwesomeIcon icon={faLinkedinIn} /></a></td>
                                <td><a href={linkedinUrl} target='blank'>LinkedIn</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='columnContainer listBox'>
                    <label htmlFor='contact' className='standardCursor'>Contact Us</label>
                    <ul name='contact'>
                        <li><a href={`mailto:${email}`}>{email}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default SiteFooter;
